<template>
<div>


    <b-card title="Bulk SMS Rate Cards">
        <!-- input search -->
        
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
            </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm }" :pagination-options="{
        enabled: true,
        perPage:pageLength
      }">
            <template slot="table-row" slot-scope="props">

                <!-- Column: Name -->
                <div v-if="props.column.field === 'network_name'" class="text-nowrap">
             
                    <span class="text-nowrap">{{ props.row.network_name }}</span>
                   
                </div>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                        {{ props.row.status | rateCardStatus}}
                   
                </span>
                <span v-else-if="props.column.field === 'country'">
                
                    <p>
                    {{ props.row.country }} 
                       <small>{{props.row.is_local}} - +{{props.row.country_code}}</small> 
                    </p>
                </span>
                
                <span v-else-if="props.column.field === 'unit_cost'">
                        {{ props.row.currency}} {{ props.row.unit_cost | formatAmount}}
                    
                </span>
                <span v-else-if="props.column.field === 'balance_sms'">
                        {{ balance / props.row.unit_cost | formatNumber}} Units
                    
                </span>
                
                
                <span v-else-if="props.column.field === 'sender_id_cost'">
                       {{ props.row.currency}} {{ props.row.sender_id_cost | formatAmount}}
                    
                </span>
                <span v-else-if="props.column.field === 'created_at'">
                       {{ props.row.created_at | formatDate}}
                    
                </span>
                
                <!-- Column: Action -->
               

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card>
</div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard
} from 'bootstrap-vue'
import {
    VueGoodTable
} from 'vue-good-table'
import store from '@/store/index'
import $ from 'jquery'
import numeral from "numeral"
import moment from "moment"
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BCard
    },
    data() {
        return {
            pageLength: 8,
            dir: false,
            columns: [{
                    label: 'Network',
                    field: 'network_name',
                },
                {
                    label: 'Country',
                    field: 'country',
                },
                {
                    label: 'Unit Cost',
                    field: 'unit_cost',
                },
                {
                    label: 'SMS Balance Equivalent',
                    field: 'balance_sms',
                },
                {
                    label: 'Sender ID Cost',
                    field: 'sender_id_cost',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label:'Date',
                    field:'created_at'
                }
            ],
            rows: [],
            searchTerm: '',
            status: [{
                13: 'Active',
                11: 'Pending',
                12: 'Rejected',
            }, {
                13: 'light-success',
                11: 'light-primary',
                12: 'light-danger',
            }],
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Active: 'light-success',
                Pending: 'light-primary',
                Rejected: 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    mounted() {
        let vm = this
        vm.getSummaryData()
        
    },
    methods: {
        getSummaryData() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/wallet',
                type: "get",
                async: true,
                data: {
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {
                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.balance = 0
                    } else {
                        let result = response.data.data;
                        vm.balance = Number(result.balance) + Number(result.bonus)
                        vm.getAllRateCards()

                    }

                },
                error: function (jQxhr, status, error) {
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({ name: 'login' })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }

                }
            });

        },
        getAllRateCards() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/bulk_ratecard',
                type: "get",
                async: true,
                data: {
                    offset: "",
                    sort: "",
                    export: "",
                    limit: "",
                    start: "",
                    end: "",
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.rows = []
                    } else {
                        let result = response.data.data.data;
                        vm.rows = result

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.rows = []
                }
            });
        }
    },
    filters: {
         formatAmount: function (value) {
             if(value <= 0){
                return 0.00
            }
            return numeral(value).format("0,0.00");
        },
        formatNumber: function (value) {
            if(value <= 0){
                return 0
            }
            return numeral(value).format("0,0");
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
        rateCardStatus(value) {
            if (value == '0') {
                return "Pending"
            } else if (value == '1') {
                return "Active"
            } else {
                return "Disabled"
            }
        },
        shortCodeStatusColor(value) {
            if (value == '1') {
                return "light-sucess"
            } else if (value == '0') {
                return "light-warning"
            } else {
                return "light-danger"
            }
        }
    }

}
</script>
